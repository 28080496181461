
var lastScrollTop = 0;

window.addEventListener("scroll", function () {
	let header = document.querySelector('.site-header');
	header.style.backgroundColor = 'initial';
	if (window.innerWidth > 768) {
		header.style.paddingTop = '3.5rem';
	}
	else {header.style.paddingTop = '32px';}
	header.style.paddingBottom = '0';
	if (window.pageYOffset > 70) {
		var st = window.pageYOffset || document.documentElement.scrollTop;
		header.style.paddingTop = '2.5rem';
		header.style.paddingBottom = '2.5rem';
		if (st > lastScrollTop) {
			header.classList.add('scrolling-header');
		} else {
			header.classList.remove('scrolling-header');
			header.style.backgroundColor = 'white';
		}
		lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	}
}, false);
