var App = App || {};

(function ($) {
	var $body, OCE;

	// *** READY ***
	$(document).ready(function () {
		// Staticizzo variabili $ (in modo da evitare continue intrusioni nel dom)
		$body = $("body");
		commonFnHandler();
		App.triggerEvent("main::appReady");

		// ---------------------------------- //
		// Video autoplay - Homepage
		// ---------------------------------- //

		/* Get iframe src attribute value i.e. YouTube video url
			 and store it in a variable */
		var url = $("#homepage-video").attr("src");

		/* Remove iframe src attribute on page load to
		prevent autoplay in background */
		$("#homepage-video").attr("src", "");

		/* Assign the initially stored url back to the iframe src
		attribute when modal is displayed */
		$("#videoModal").on("shown.bs.modal", function () {
			$("#homepage-video").attr("src", url);
		});

		/* Assign empty url value to the iframe src attribute when
		modal hide, which stop the video playing */
		$("#videoModal").on("hide.bs.modal", function () {
			$("#homepage-video").attr("src", "");
		});
	});

	/** function to select div element */
	const select = (selector) => document.querySelectorAll(selector);

	/** declaring variables for element with animation */
	const mosaicLeftList = select(".mosaic-left");
	const mosaicRightList = select(".mosaic-right");
	const doubleParagraphList = select(".double-paragraph");
	const imageParagraphList = select(".image_paragraph__content");
	const projectIntroList = select(".project-intro");
	const projectCoreList = select(".project-core__animation");
	const contactHighlightList = select(".contact-highlight");
	const worksList = select(".works");
	const careersList = select(".careers");
	const highlightContent = select(".highlight__content");
	const otherProjects = document.querySelector("#other-projects");
	const loadingPage = document.getElementById("loading-page");
	const heroBannerAbout = document.getElementById("hero-banner-about");

	/** if loading page is not null add timeout */
	let timeOut = loadingPage ? 1800 : 0;

	/**
	 * Check if a given div element is partially visible in viewport
	 *
	 * @param {Node} el
	 * @param {number} minorHeight
	 * @returns boolean
	 */
	const isPartiallyVisible = (el, minorHeight) => {
		if (!el || typeof el.getBoundingClientRect !== 'function') return false;
		const rect = el?.getBoundingClientRect();
		const windowHeight = window.innerHeight || document.documentElement.clientHeight;
		return rect.bottom >= 0 && rect.top <= windowHeight - minorHeight;
	};

	/**
	 * Add class to start animation at the first loading of page if a
	 * given div is partially visible
	 *
	 * @param {NodeList, Node} divElement
	 * @param {boolean} onTimeout
	 * @param {number} minorHeight
	 */
	const startAnimation = (divElement, onTimeout = true, minorHeight = 0) => {
		if (!divElement) return;
		const elements = divElement.length ? [...divElement] : [divElement];
		timeOut = onTimeout ? timeOut : 0;
		elements.forEach((element) => {
			if (isPartiallyVisible(element, minorHeight)) {
				setTimeout(() => {
					element.style.opacity = '1';
					element.classList.add("active-animation");
				}, timeOut);
			}
		});
	};

	/** handle scroll to active animation */
	const handleScroll = () => {
		startAnimation(otherProjects, false);
		startAnimation(mosaicRightList, false, 100);
		startAnimation(mosaicLeftList, false, 100);
		startAnimation(doubleParagraphList, false);
		startAnimation(heroBannerAbout, false);
		startAnimation(imageParagraphList, false);
		startAnimation(projectIntroList, false);
		startAnimation(projectCoreList, false);
		startAnimation(worksList, false);
		startAnimation(contactHighlightList, false);
		startAnimation(careersList, false);
		startAnimation(highlightContent, false);
	};

	window.addEventListener("scroll", handleScroll);

	/** if loading page is null start animation with a small delay for components at the top of the page */
	!loadingPage && window.setTimeout(() => startAnimation(heroBannerAbout), 700);
	!loadingPage && window.setTimeout(() => startAnimation(doubleParagraphList), 700);
	!loadingPage && window.setTimeout(() => startAnimation(projectIntroList), 700);
	!loadingPage && window.setTimeout(() => startAnimation(worksList), 700);
	!loadingPage && window.setTimeout(() => startAnimation(contactHighlightList), 700);

	/** active animation for components that are not at the top of the page*/
	startAnimation(otherProjects);
	startAnimation(mosaicRightList, false, 100);
	startAnimation(mosaicLeftList, false, 100);
	startAnimation(doubleParagraphList);
	startAnimation(imageParagraphList);
	startAnimation(projectCoreList);
	startAnimation(careersList);
	startAnimation(highlightContent);

	const commonFnHandler = () => {
		setBrowserClass();
		initSmartListener();
		setVHFix();
		openOverlayMenu();
		hoverSelectedWorks();
		closeOverlayMenu();

		OCE = gajus.orientationchangeend({});
		// Attach event listener to the "orientationchangeend" event.
		OCE.on("orientationchangeend", () => {
			setVHFix();
		});
	};

	const setBrowserClass = () => {
		const ua = window.navigator.userAgent;
		if (ua.indexOf("Trident/") > 0 || ua.indexOf("MSIE ") > 0) {
			$("html").addClass("ie");
		}
		if (ua.indexOf("Edge/") > 0) {
			$("html").addClass("edge");
		}
	};

	const initSmartListener = () => {
		const smartScroll = App.throttle(function () {
			App.triggerEvent("main::smartScroll");
		}, 200);
		const smartResize = App.debounce(function () {
			App.triggerEvent("main::smartResize");
		}, 250);
		$(window).on("scroll", smartScroll);
		$(window).on("resize", smartResize);
	};

	const openOverlayMenu = () => {
		$(".hamburger-menu").click(function () {
			$(".overlay").removeClass("menu-closed");
			$(".overlay").addClass("menu-opened");
			$(".hibo-header").addClass("d-none");
		});
	};

	const hoverSelectedWorks = () => {
		$(".sw__box").on({
			mouseenter: function () {
				$(".hamburger-menu").addClass("invert");
			},
			mouseleave: function () {
				$(".hamburger-menu").removeClass("invert");
			},
		});
	};

	const closeOverlayMenu = () => {
		$(".overlay-nav__close").click(function () {
			$(".overlay").removeClass("menu-opened");
			$(".overlay").addClass("menu-closed");
			$(".hibo-header").removeClass("d-none");
		});
	};

	const setVHFix = () => {
		var vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", vh + "px");
	};
})(jQuery);
