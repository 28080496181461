const bannerDinova = document.getElementById('bannerDinova');
const bannerTrigger = document.getElementById('bannerTrigger');
// const bannerRemove = document.getElementById('bannerRemove');

// function getCookie(cname) {
//   let name = cname + "=";
//   let decodedCookie = decodeURIComponent(document.cookie);
//   let ca = decodedCookie.split(';');
//   for(let i = 0; i <ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }

// const bannerClosed = getCookie("bannerClosed");
// console.log(bannerClosed);

if (bannerDinova) {
	setTimeout(() => {
		bannerDinova.classList.add("is-open");
	}, 5000);
	bannerTrigger.addEventListener('click', e => {
		e.stopPropagation();
		bannerDinova.classList.toggle("is-open");
	})
	// bannerRemove.addEventListener('click', e => {
	// 	e.stopPropagation();
	// 	bannerDinova.remove();
	// 	document.cookie = "bannerClosed=true;";
	// 	// console.log(getCookie("bannerClosed"))
	// })
}
