"use strict";

var App = App || {};

;(function($){
	const developmentHost = [
		'localhost',
		'root.devs',
	];

  var appUtility = {
    checkDebug: () => {
			for(const h in developmentHost){
				if(window.location.host.indexOf(developmentHost[h]) > -1){
					return true;
				}
			}
      return false;
    },

    triggerEvent : (eventName, args=[], el = window) => {
      if(App.DEBUG){
        let text = "[event] " + eventName;
        if(el != window && (el.id || el.className)){
          text += el.id != '' ? ' (#' + el.id + ')' : ' (' + el.className + ')';
        }else{
          text += ' (window)';
        }
        console.log(text);
      }
      $(el).trigger(eventName, args);
    },
    debounce : (func, wait, immediate) => {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    throttle : (func, limit) => {
      let lastFunc
      let lastRan
      return function() {
        const context = this
        const args = arguments
        if (!lastRan) {
          func.apply(context, args)
          lastRan = Date.now()
        } else {
          clearTimeout(lastFunc)
          lastFunc = setTimeout(function() {
            if ((Date.now() - lastRan) >= limit) {
              func.apply(context, args)
              lastRan = Date.now()
            }
          }, limit - (Date.now() - lastRan))
        }
      }
    },
    isIE : () => {
      const ua = window.navigator.userAgent;
      if(ua.indexOf('Trident/') > 0 || ua.indexOf('MSIE ') > 0)
        return 'ie';

      if(ua.indexOf('Edge/') > 0)
        return 'edge';

      return false;
		}
	}

	// Quando riusciremo finalmente a seppellire IE..
  //Object.assign(App, appUtility);
  // intanto
  $.extend(App, appUtility);
  App.DEBUG = App.checkDebug();
}(jQuery));

